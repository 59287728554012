import React from 'react';

const ForgotPassword = React.lazy(() => import(/* webpackChunkName: "forgotpassword" */ "./ForgotPassword"));
const ForgotPasswordLoadable = (props) => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ForgotPassword {...props}/>
    </React.Suspense>
);

export default ForgotPasswordLoadable;
