import React from 'react';
import ButtonDeleteOrder from "./ButtonDeleteOrder";
import ButtonOrder from "./ButtonOrder";

const ButtonMenu = props => {
    return (
        props.record.itemId !== '-' ?
            (props.record.order === null ?
                    <ButtonOrder id={props.record.id}
                                 itemId={props.record.itemId}
                                 rov={props.record.rov}
                                 handler={props.handlerOrderMenuItem}
                                 date={props.record.date}/> :
                    (props.record.order.menu.active && <ButtonDeleteOrder rov={props.record.order.rov}
                                                                          itemId={props.record.itemId}
                                                                          date={props.record.order.date}
                                                                          handler={props.handlerOrderMenuItem}/>)
            ) : null
    );
};

export default ButtonMenu;
