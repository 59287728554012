import moment from "moment";
import React from "react";
import ButtonMenu from "./ButtonMenu";
import {List} from 'antd';
import {menuClassName} from './MenuRovUtil';

const skLocale = require('moment/locale/sk');

const MenuRovListComponent = props => {
    const metaTitle = (item) => {
        return (
            <div>
                <div>{item.rov}</div>
                <div>{moment(item.date).locale('sk', skLocale).format('dddd DD.MM.YYYY')}</div>
            </div>
        )
    };

    return (
        <List
            bordered
            dataSource={props.dataSource}
            pagination={{pageSize: 10, showLessItems: true}}
            renderItem={item => (
                <List.Item key={item.id} className={menuClassName(item)}>
                    <List.Item.Meta
                        title={metaTitle(item)}
                        description={item.description}
                    />
                    <div>
                        <div className='list-menu-itemId'>{item.itemId}</div>
                        <ButtonMenu record={item} handlerOrderMenuItem={props.handlerOrderMenuItem} />
                    </div>
                </List.Item>
            )}
        />
    );
};

export default MenuRovListComponent;
