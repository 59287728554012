import React from 'react';

const Signup = React.lazy(() => import(/* webpackChunkName: "signup" */ "./Signup"));
const SignupLoadable = (props) => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <Signup {... props}/>
    </React.Suspense>
);

export default SignupLoadable;
