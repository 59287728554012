import React from 'react';
import {
    Link,
    withRouter
} from 'react-router-dom';
import './AppHeader.css';
import {
    HomeOutlined,
    SolutionOutlined,
    UserOutlined,
    UserAddOutlined,
    SendOutlined,
    SettingOutlined
} from '@ant-design/icons';
import {Layout, Menu} from 'antd';

const Header = Layout.Header;

function getMenuItems(currentUser) {
    if (currentUser) {
        const items =
            [
                {
                    label: `@${currentUser.username}`,
                    key: 'logged',
                    children: [
                        {
                            label: <Link to="/">Jedálny lístok</Link>,
                            key: '/',
                            icon: <HomeOutlined/>
                        },
                        {
                            label: <Link to="/invoice">Sumár</Link>,
                            key: '/invoice',
                            icon: <SolutionOutlined/>
                        },
                        {
                            label: <Link to={`/settings/${currentUser.username}`}>Nastavenia</Link>,
                            key: `/settings/${currentUser.username}`,
                            icon: <SettingOutlined/>
                        },
                        {
                            label: <Link to="/logout">Odhlásenie</Link>,
                            key: '/logout',
                            icon: <SendOutlined/>
                        }]
                }
            ];
        return items;
    } else {
        return (
            [
                {
                    label: <Link to="/login">Prihlásenie</Link>,
                    key: '/login',
                    icon: <UserOutlined/>
                },
                {
                    label: <Link to="/signup">Registrácia</Link>,
                    key: '/signup',
                    icon: <UserAddOutlined/>
                }
            ])
    }
}


const AppHeader = ({currentUser, onLogout, location}) => {
    const handleMenuClick = ({key}) => {
        if (key === "/logout") {
            onLogout();
        }
    };

    return (
        <Header className="app-header">
            <div className="container">
                <div className="app-header-flex">
                    <div className="app-title">
                        <Link to="/"
                              title={process.env.REACT_APP_NAME + '-' + process.env.REACT_APP_VERSION}>&tau;axovia</Link>
                    </div>
                    <Menu
                        className="app-name"
                        theme="dark"
                        defaultSelectedKeys={['/']}
                        selectedKeys={[location.pathname]}
                        onClick={handleMenuClick}
                        mode="horizontal"
                        triggerSubMenuAction="click"
                        items={getMenuItems(currentUser)}
                    />
                </div>
            </div>
        </Header>
    );
};

export default withRouter(AppHeader);
