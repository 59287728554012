import {ACCESS_TOKEN} from '../constants/constants';

const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    });

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response => {
                if (response.status === 401 || response.status === 403) {
                    return Promise.reject(response);
                }
                return response.json().then(json => {
                    if (!response.ok) {
                        return Promise.reject(json);
                    }
                    return json;
                })
            }
        );
};

export const login = (loginRequest) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/auth/signin",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
};

export const signup = (signupRequest) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/auth/signup",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
};

export const checkUsernameAvailability = (username) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/user/checkUsernameAvailability?username=" + username,
        method: 'GET'
    });
};

export const checkEmailAvailability = (email) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/user/checkEmailAvailability?email=" + email,
        method: 'GET'
    });
};

export const getCurrentUser = () => {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/user/me",
        method: 'GET'
    });
};

export const getUserProfile = (username) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/users/" + username,
        method: 'GET'
    });
};

export const getUserIdentity = () => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/user/me/full",
        method: 'GET'
    });
};

export const updateUserIdentity = (userIdentityRequest) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/user/me/full",
        method: 'POST',
        body: JSON.stringify(userIdentityRequest)
    });
};

export const getAllRfidCards = () => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/rfid',
        method: 'GET'
    });
};

export const unpairRfid = (rfidId) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/rfid?code=" + rfidId,
        method: 'DELETE',
    });
};

export const addRfidCard = (rfidCardRequest) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/rfid",
        method: 'POST',
        body: JSON.stringify(rfidCardRequest)
    });
};

export const addMenu = (menuItemRequest) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/rov-menu",
        method: 'POST',
        body: JSON.stringify(menuItemRequest)
    });
};

export const orderMenu = (orderRequest) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/order",
        method: 'POST',
        body: JSON.stringify(orderRequest)
    });
};

export const getAllMenuRovs = () => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/rov-menu/order',
        method: 'GET'
    });
};

export const deleteOrder = (rov, date) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/order?rov=" + rov + "&date=" + date,
        method: 'DELETE',
    });
};

export const forgotPassword = (forgotPassword) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/pwd-recovery",
        method: 'POST',
        body: JSON.stringify(forgotPassword)
    });
};

export const resetPassword = (resetPassword) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/pwd-recovery",
        method: 'PUT',
        body: JSON.stringify(resetPassword)
    });
};

export const getOrderAndServeList = () => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/order/list',
        method: 'GET'
    });
};

export const getOrganizationSetting = () => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/user/organization',
        method: 'GET'
    });
};

export const updateOrganization = (organizationRequest) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/user/organization",
        method: 'POST',
        body: JSON.stringify(organizationRequest)
    });
};

export const validateRegistration = (validateRegistration) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/registration-confirm",
        method: 'POST',
        body: JSON.stringify(validateRegistration)
    });
};

export const checkShutdown = () => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/shutdown",
        method: 'GET'
    });
};

export const postAgreed = ({token}) => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/exchange?token=" + token,
        method: 'GET'
    });
};

export const getConfig = () => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/config",
        method: 'GET'
    });
};

