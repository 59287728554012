import React, {useState, useEffect} from 'react';
import './App.css';
import {Route, Switch, withRouter} from 'react-router-dom';

import {getCurrentUser, getConfig} from '../util/APIUtils';
import {ACCESS_TOKEN} from '../constants/constants';

import Login from '../user/login/Login';
import Signup from '../user/signup/SignUpLoadable';
import AppHeader from '../common/AppHeader';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';

import {Layout, notification} from 'antd';
import MenuRovList from "../menurov/MenuRovListWrapper";
import ForgotPassword from "../user/resetpwd/ForgotPasswordLoadable";
import ResetPassword from "../user/resetpwd/ResetPasswordLoadable";
import PrivateRoute from "../common/PrivateRoute";
import Setting from "../user/setting/SettingLoadable";
import ValidationRegistration from "../user/validation/ValidationRegistration";
import OrderAndServeList from '../invoice/OrderAndServeListLoadable';
import AppFooter from "../common/AppFooter";
import Agreed from "../user/agreed/Agreed";
import {ConfigProvider} from "../common/ConfigContext";

const {Content, Footer} = Layout;

const App = ({...props}) => {
    const [config, setConfig] = useState({});
    const [currentUser, setCurrentUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    notification.config({
        placement: 'topRight',
        top: 70,
        duration: 3,
    });

    const loadCurrentUser = () => {
        setIsLoading(true);
        getCurrentUser()
            .then(response => {
                setCurrentUser(response);
                setIsAuthenticated(true);
                setIsLoading(false);
            }).catch(error => {
            setIsLoading(false);
            setIsAuthenticated(false);
            setCurrentUser(null);
        });
    };

    function loadConfig() {
        getConfig()
            .then(response => setConfig(response))
            .catch(error => setConfig({}))
    }

    useEffect(() => {
        loadCurrentUser();
    }, []);

    const handleLogin = () => {
        loadCurrentUser();
        loadConfig();
        props.history.push("/");
    };

    const handleLogout = () => {
        const redirectTo = "/";
        const notificationType = "success";
        const description = "Odhlásili sme Ťa.";
        localStorage.removeItem(ACCESS_TOKEN);

        setCurrentUser(null);
        setIsAuthenticated(false);

        props.history.push(redirectTo);

        notification[notificationType]({
            message: 'TaxoviaClientApp',
            description: description,
        });

        window.location.reload();
    };

    const handleFinish = (message) => {
        notification.success({
            message: 'TaxoviaClientApp',
            description: message,
        });
        props.history.push("/");
    };

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return (
        <Layout className="app-container">
            <AppHeader isAuthenticated={isAuthenticated}
                       currentUser={currentUser}
                       onLogout={handleLogout}/>

            <ConfigProvider config={config}>
                <Content className="app-content">
                    <div className="app-container">
                        <Switch>
                            <Route exact path="/"
                                   render={(props) => <MenuRovList isAuthenticated={isAuthenticated}
                                                                   currentUser={currentUser}
                                                                   handleLogout={handleLogout} {...props} />}>
                            </Route>
                            <Route path="/login" render={(props) => <Login onLogin={handleLogin} {...props} />}></Route>
                            <Route path="/forgot-password"
                                   render={(props) => <ForgotPassword onFinish={handleFinish} {...props} />}></Route>
                            <Route path="/reset"
                                   render={(props) => <ResetPassword onFinish={handleFinish} {...props} />}></Route>
                            <Route path="/registration-confirm" render={(props) => <ValidationRegistration
                                onFinish={handleFinish} {...props} />}></Route>
                            <Route path="/signup" component={Signup}></Route>
                            <Route path="/settings/:username"
                                   render={(props) => <Setting isAuthenticated={isAuthenticated}
                                                               handleLogout={handleLogout}
                                                               currentUser={currentUser} {...props}  />}>
                            </Route>
                            <Route path="/agreed" component={Agreed}/>
                            <PrivateRoute authenticated={isAuthenticated} path="/invoice" component={OrderAndServeList}
                                          handleLogout={handleLogout}></PrivateRoute>
                            <Route component={NotFound}></Route>
                        </Switch>
                    </div>
                </Content>
            </ConfigProvider>
            <Footer style={{textAlign: 'center'}}>
                <AppFooter currentUser={currentUser}/>
            </Footer>
        </Layout>
    );
};

export default withRouter(App);
