import Order from "./Order";
import React from "react";

const ButtonOrder = props => {
    return (
        <Order menuRovId={props.id}
               itemId={props.itemId}
               date={props.date}
               rov={props.rov}
               handlerOrderMenuItem={props.handler}/>
    );
};

export default ButtonOrder;
