import React, {useState} from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Table } from "antd";
import {menuClassName} from "./MenuRovUtil";
import moment from "moment";
import ButtonMenu from "./ButtonMenu";
import {FILTER_ROV} from "../constants/constants";

const skLocale = require('moment/locale/sk');

const MenuRovListTable = props => {
    const [filteredRovValue, setFilteredRovValue] = useState(JSON.parse(localStorage.getItem(FILTER_ROV)) || []);

    const columns = [{
        title: 'Dátum',
        dataIndex: 'date',
        key: 'date',
        className: 'menu-date',
        render: (text, record) => (moment(text).locale('sk', skLocale).format('dddd DD.MM.YYYY'))
    }, {
        title: 'ROV',
        dataIndex: 'rov',
        key: 'rov',
        className: 'menu-rov',
        filters: [{
            text: 'RAŇAJKY',
            value: 'RAŇAJKY',
        }, {
            text: 'OBED',
            value: 'OBED',
        }, {
            text: 'VEČERA',
            value: 'VEČERA',
        }],
        filterMultiple: true,
        onFilter: (value, record) => record.rov.indexOf(value) === 0,
        filteredValue: filteredRovValue || null,
    }, {
        title: 'Výber',
        dataIndex: 'itemId',
        key: 'itemId',
        className: 'menu-itemid',
    }, {
        title: 'Menu',
        dataIndex: 'description',
        key: 'description',
        className: 'menu-description'
    }, {
        title: 'Akcia',
        key: 'action',
        className: 'menu-action',
        render: (text, record) => (<ButtonMenu record={record} handlerOrderMenuItem={props.handlerOrderMenuItem}/>)
    }];

    const handleChange = (pagination, filters, sorter) => {
        setFilteredRovValue(filters.rov);
        localStorage.setItem(FILTER_ROV, JSON.stringify(filters.rov));
    };

    return (
        <Table
            rowKey={(record) => record.id}
            dataSource={props.dataSource}
            pagination={{pageSize: 25, showLessItems: true}}
            columns={columns}
            locale={{emptyText: 'Nie je k dispozícii jedálny lístok'}}
            onChange={handleChange}
            loading={{
                spinning: props.isLoading,
                indicator: <LoadingOutlined spin />
            }}
            rowClassName={(record, index) => menuClassName(record)}
        />
    );
};

export default MenuRovListTable;
