import React from 'react';

const ResetPassword = React.lazy(() => import(/* webpackChunkName: "resetpassword" */ "./ResetPassword"));
const ResetPasswordLoadable = (props) => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ResetPassword {... props}/>
    </React.Suspense>
);

export default ResetPasswordLoadable;
