import React from 'react';
import {login} from '../../util/APIUtils';
import './Login.css';
import {Link} from 'react-router-dom';
import {ACCESS_TOKEN} from '../../constants/constants';

import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Input, Button, notification, Form} from 'antd';
import Title from "../../util/Title";
import GA from "../../util/GoogleAnalytics";

const FormItem = Form.Item;

const Login = (props) => {
    return (
        <div className="login-container">
            <Title pageTitle="Prihlásenie"/>
            <h2 className="page-title">Prihlásenie</h2>
            <div className="login-content">
                <LoginForm onLogin={props.onLogin}/>
            </div>
        </div>
    );
};

const LoginForm = (props) => {
        const [form] = Form.useForm();

        function handleOnFinish(values) {
            login(values)
                .then(response => {
                    GA.Event("SYSTEM", "LOGIN", "SUCCESS");
                    localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                    props.onLogin();
                }).catch(error => {
                GA.Event("SYSTEM", "LOGIN", "ERROR");
                if (error.status === 401 || error.status === 403) {
                    notification.error({
                        message: 'Taxovia App',
                        description: 'Prihlasovacie meno, alebo heslo nie je správne. Skúste ešte raz!'
                    });
                } else {
                    notification.error({
                        message: 'Taxovia App',
                        description: 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                    });
                }
            });
        };

        return (
            <Form form={form} onFinish={handleOnFinish} className="login-form">
                <FormItem name="usernameOrEmail"
                          rules={[{required: true, message: 'Zadajte prihlasovacie meno, alebo email!'}]}>
                    <Input
                        prefix={<UserOutlined/>}
                        size="large"
                        name="usernameOrEmail"
                        placeholder="Prihlasovacie meno alebo email"/>
                </FormItem>
                <FormItem name="password" rules={[{required: true, message: 'Prosím zadajte heslo!'}]}>
                    <Input
                        prefix={<LockOutlined/>}
                        size="large"
                        name="password"
                        type="password"
                        placeholder="Heslo"/>
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" size="large"
                            className="login-form-button">Prihlásenie</Button>
                    alebo <Link to="/signup">sa zaregistrujte teraz!</Link>
                    <span className="forgot-pwd"><Link to="/forgot-password">Zabudnuté heslo</Link></span>
                </FormItem>
            </Form>
        );
    }
;


export default Login;
