import React from 'react';
import {Skeleton} from "antd";

const OrderAndServeList = React.lazy(() => import(/* webpackChunkName: "orderandserver" */ "./OrderAndServeWrapper"));
const OrderAndServeListLoadable = (props) => (
    <React.Suspense fallback={<Skeleton />}>
        <OrderAndServeList {...props}/>
    </React.Suspense>
);

export default OrderAndServeListLoadable;
