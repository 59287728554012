import DeleteOrder from "./DeleteOrder";
import React from "react";

const ButtonDeleteOrder = props => {
    return (
        <DeleteOrder itemId={props.itemId}
                     rov={props.rov}
                     date={props.date}
                     handlerOrderMenuItem={props.handler}/>
    );
};

export default ButtonDeleteOrder;
