import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Alert, BackTop, PageHeader, notification} from 'antd';
import './MenuRovList.css';
import {getAllMenuRovs} from "../util/APIUtils";
import MenuRovFooter from "./MenuRovFooter";
import NoMenuRovFound from "./NoMenuRovFound";
import Price from "../price/Price";
import {Mobile, Default} from '../util/Helpers';
import MenuRovListComponent from "./MenuRovListComponent";
import MenuRovListTable from "./MenuRovListTable";
import Title from "../util/Title";
import Shutdown from "../shutdown/Shutdown";

const MenuRovListWrapper = ({isAuthenticated, handleLogout}) => {
    const [menuRovs, setMenuRovs] = useState([]);
    const [priceList, setPriceList] = useState([]);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const loadMenuRovList = () => {
        let promise = getAllMenuRovs();

        if (!promise) {
            return;
        }

        setIsLoading(true);

        promise
            .then(response => {
                setMenuRovs(response.menu.slice());
                setMessage(response.message);
                setPriceList(response.price);
                setIsLoading(false);
            }).catch(error => {
            setIsLoading(false);
            if (error.status === 401) {
                notification.error({
                    message: 'Taxovia App',
                    description: 'Neplatné prihlásenie!'
                });
                handleLogout();
            }
        });
    };

    const handlerOrderMenuItem = () => {
        loadMenuRovList();
    };

    useEffect(() => {
        if (isAuthenticated) {
            loadMenuRovList();
        } else {
            setMenuRovs([]);
            setPriceList([]);
            setIsLoading(false);
        }
    }, [isAuthenticated]);

    return (
        <div className="menu-rov-container">
            <Shutdown>
                {
                    !isLoading && !isAuthenticated ? (
                        <NoMenuRovFound/>
                    ) : (
                        <div>
                            <Title pageTitle="Menu ROV"/>
                            {
                                message === null ? null : (
                                    <div className="taxovia-alert">
                                        <Alert type={message.type}
                                               message={message.message}
                                               description={message.description}
                                               showIcon
                                        />
                                    </div>
                                )
                            }
                            <PageHeader title="Jedálny lístok" subTitle="Objednávky, cenník"/>
                            <BackTop/>
                            <Mobile>
                                <MenuRovListComponent dataSource={menuRovs}
                                                      handlerOrderMenuItem={handlerOrderMenuItem}/>
                            </Mobile>
                            <Default>
                                <MenuRovListTable dataSource={menuRovs}
                                                  handlerOrderMenuItem={handlerOrderMenuItem}
                                                  isLoading={isLoading}/>
                            </Default>
                            <MenuRovFooter/>
                            <Price priceList={priceList} isLoading={isLoading}/>
                        </div>
                    )
                }
            </Shutdown>
        </div>
    );
};

export default withRouter(MenuRovListWrapper);
