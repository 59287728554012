import React from 'react';
import {Skeleton} from "antd";

const Setting = React.lazy(() => import(/* webpackChunkName: "setting" */ "./Setting"));
const SettingLoadable = (props) => (
    <React.Suspense fallback={<Skeleton />}>
        <Setting {...props}/>
    </React.Suspense>
);

export default SettingLoadable;
