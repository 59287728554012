import React, {useEffect, useState} from 'react';
import {checkShutdown} from "../util/APIUtils";
import {Result, Spin} from 'antd';
import "./Shutdown.css";

const Shutdown = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [shutdown, setShutdown] = useState(false);

    useEffect(() => {
        let isCancelled = false;
        let promise = checkShutdown();

        if (!promise) {
            return;
        }

        setIsLoading(true);
        promise
            .then(response => {
                if (!isCancelled) {
                    setShutdown(response.shutdown);
                    setIsLoading(false);
                }
            }).catch(error => {
            setIsLoading(false);
        });
        return () => {
            isCancelled = true;
        }
    }, []);

    if (isLoading) {
        return (<div className="shutdown-container"><Spin size="large"/></div>);
    }

    return (
        shutdown ? <Result
            status="warning"
            title="Prebieha údržba systému!"
        /> : props.children
    );
};

export default Shutdown;
