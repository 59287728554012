import React from "react";
import { Carousel } from 'antd';
import './NoMenuRovFound.css'
import Title from "../util/Title";

const NoMenuRovFound = () => {
    return (
        <div className="no-menu-container">
            <Title siteTitle="Taxovia"/>
            <Carousel autoplay autoplaySpeed={5000}>
                <div><img src={`./assets/images/soup1.jpg`} alt="soup"/></div>
                <div><img src={`./assets/images/kebab.jpg`} alt="kebab"/></div>
                <div><img src={`./assets/images/salat.jpg`} alt="salat"/></div>
                <div><img src={`./assets/images/chicken-meal.jpg`} alt="chicken-meal"/></div>
            </Carousel>
            <div className="no-menu-rov-found">
                Návod na použitie nájdete na <a href={'https://navod.taxovia.eu'} target={'_blank'}>navod.taxovia.eu</a>
            </div>
        </div>
    );
};

export default NoMenuRovFound;
