import React from "react";
import {FolderAddOutlined} from '@ant-design/icons';
import {Button, notification} from "antd";
import {orderMenu} from "../util/APIUtils";
import GA from "../util/GoogleAnalytics";
import {useConfigContext} from "../common/ConfigContext";
import moment from "moment";

const skLocale = require('moment/locale/sk');

const Order = (props) => {

    const {useSmallOrder} = useConfigContext();

    function handlerOrder(event, quantity) {
        event.preventDefault();
        const orderRequest = {
            menuItemId: props.menuRovId,
            quantity: quantity
        };

        const gaLabel = props.itemId + '_' + props.rov + '_' + moment(props.date).locale('sk', skLocale).format('DDMMYYYY');
        GA.Event("MENU", "ORDER", "" + gaLabel);

        orderMenu(orderRequest)
            .then(response => {
                notification.success({
                    message: 'Taxovia App',
                    description: response.message,
                });
                props.handlerOrderMenuItem();
            }).catch(error => {
            if (error.status === 401) {
                notification.error({
                    message: 'Taxovia App',
                    description: 'Nemáte právo niečo odstraňovať!'
                });
            } else {
                notification.error({
                    message: 'Taxovia App',
                    description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                });
            }
        });
    }

    const orderText = useSmallOrder ? 'Veľká porcia' : 'Objednať';

    return <>
        <Button type="default" onClick={e => handlerOrder(e, 1)}><FolderAddOutlined/>{orderText}</Button>
        {useSmallOrder &&
        <Button type="default" onClick={e => handlerOrder(e, 0.5)}><FolderAddOutlined/>Malá porcia</Button>}
    </>;
};

export default Order;
