import React from "react";
import { QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, notification, Popconfirm } from "antd";
import {deleteOrder} from "../util/APIUtils";
import GA from "../util/GoogleAnalytics";
import moment from "moment";

const skLocale = require('moment/locale/sk');

const DeleteOrder = (props) => {
    const handlerOrder = (event) => {
        event.preventDefault();

        const gaLabel = props.itemId + '_' + props.rov + '_' + moment(props.date).locale('sk', skLocale).format('DDMMYYYY');
        GA.Event("MENU", "DELETE-ORDER", gaLabel);

        deleteOrder(props.rov, props.date)
            .then(response => {
                notification.success({
                    message: 'Taxovia App',
                    description: response.message,
                });
                props.handlerOrderMenuItem();
            }).catch(error => {
            if (error.status === 401) {
                notification.error({
                    message: 'Taxovia App',
                    description: 'Nemáte právo niečo odstraňovať!'
                });
            } else {
                notification.error({
                    message: 'Taxovia App',
                    description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                });
            }
        });
    };

    return (
        <Popconfirm title="Určite mám zrušiť objednávku？"
                    onConfirm={handlerOrder}
                    okText="Áno, zrušiť"
                    cancelText="Nie"
                    icon={<QuestionCircleOutlined />}>
            <Button type="danger" ghost><WarningOutlined />Zrušiť</Button>
        </Popconfirm>
    );
};

export default DeleteOrder;
