import React from 'react';
import {withRouter} from 'react-router-dom';
import './ValidateRegistration.css';
import {Button, Form, notification} from 'antd';
import {validateRegistration} from "../../util/APIUtils";
import queryString from 'qs';

const FormItem = Form.Item;

const ValidationRegistration = (props) => {
    const values = queryString.parse(props.location.search.slice(1));
    return (
        <div className="validation-registration-container">
            <h2 className="page-title">Overenie registrácie</h2>
            <div className="validation-registration-content">
                <ValidationRegistrationForm onFinish={props.onFinish} token={values.token}/>
            </div>
        </div>
    );
};

const ValidationRegistrationForm = (props) => {
    const handleSubmit = () => {
        const validateRegistrationRequest = {
            token: props.token
        };
        validateRegistration(validateRegistrationRequest)
            .then(response => {
                props.onFinish(response.message);
            }).catch(error => {
            if (error.status === 404) {
                notification.error({
                    message: 'Taxovia App',
                    description: error.message
                });
            } else {
                notification.error({
                    message: 'Taxovia App',
                    description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                });
            }
        });
    };

    const [form] = Form.useForm();
    return (
        <Form form={form} onFinish={handleSubmit} className="validation-registration-form">
            <FormItem>
                <p>Kliknutím na tlačítko overíte Vašu registráciu</p>
                <Button type="primary" htmlType="submit" size="large"
                        className="validation-registration-form-button">Overenie registrácie</Button>
            </FormItem>
        </Form>
    );
};

export default withRouter(ValidationRegistration);
