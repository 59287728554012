import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import skSK from 'antd/es/locale/sk_SK';
import GA from './util/GoogleAnalytics';

ReactDOM.render(
    <Router>
        { GA.init() && <GA.RouteTracker /> }
        <ConfigProvider locale={skSK}>
            <App />
        </ConfigProvider>
    </Router>, 
    document.getElementById('root')
);

registerServiceWorker();
