import React, {useEffect} from 'react';
import queryString from 'qs';
import Title from "../../util/Title";
import "./Agreed.css"
import {notification, PageHeader} from "antd";
import {postAgreed} from '../../util/APIUtils';

const Agreed = props => {
    const queryValues = queryString.parse(props.location.search.slice(1));

    const agreedPost = token => {
        postAgreed({token})
            .then(response => {
                notification.success({
                    message: 'Taxovia App',
                    description: response.message,
                });
                props.history.push("/");
            }).catch(error => {
            notification.error({
                message: 'Taxovia App',
                description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
            })
        });
    };

    useEffect(() => {
        agreedPost(queryValues.token);
    }, []);

    return (
        <div className='agreed-container'>
            <Title pageTitle="Potvrdenie súhlasu"/>
            <PageHeader title="Potvrdenie" subTitle="Súhlas z linku"/>
            Agreed
        </div>
    );
};

export default Agreed;
