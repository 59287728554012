import moment from "moment";

export const menuClassName = (record) => {
    return (record.order !== null ?
            'menu-ordered menu-' + record.rov.toLocaleLowerCase() +
            ' menu-itemid-' + record.itemId.toLocaleLowerCase() + ' menu-day-' + moment(record.date).day() :
            'menu-item menu-' + record.rov.toLocaleLowerCase() +
            ' menu-itemid-' + record.itemId.toLocaleLowerCase() + ' menu-day-' + moment(record.date).day()
    );
};
