import React from "react";
import Responsive from 'react-responsive';

export const Mobile = props => <Responsive {...props} maxWidth={767}/>;
export const Default = props => <Responsive {...props} minWidth={768}/>;

export const formatDate = (dateString) => {
    const date = new Date(dateString);

    const monthNames = [
      "Január", "Február", "Marec",
      "Apríl", "Máj", "Jún", "Júl",
      "August", "September", "Október",
      "November", "December"
    ];
  
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    return monthNames[monthIndex] + ' ' + year;
};
  
export const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr",
    "Maj", "Jun", "Jul", "Aug",
    "Sep", "Okt", "Nov", "Dec"
  ];

  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return date.getDate() + ' ' + monthNames[monthIndex] + ' ' + year + ' - ' + date.getHours() + ':' + date.getMinutes();
};
