// export const API_BASE_URL = 'http://localhost:8084/api';
// export const API_BASE_URL = '/api';
export const ACCESS_TOKEN = 'accessToken';
export const FILTER_ROV = 'filter.rov';

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;
