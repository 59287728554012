import React from "react";
import './MenuRovFooter.css'
import {Collapse} from 'antd';

const {Panel} = Collapse;

const MenuRovFooter = () => {
    return (
        <Collapse bordered={false}>
            <Panel header="*Označenie prítomnosti alergénov v potravinách" key="1" className="menu-rov-footer-panel">
                <div className='menu-rov-footer'>
                    <ol>
                        <li>Obilniny obsahujúce lepok</li>
                        <li>Kôrovce</li>
                        <li>Vajcia</li>
                        <li>Ryby</li>
                        <li>Arašidy</li>
                        <li>Sójové zrná</li>
                        <li>Mlieko</li>
                        <li>Orechy</li>
                        <li>Zeler</li>
                        <li>Horčica</li>
                        <li>Sézamové semená</li>
                        <li>Oxid siričitý a siričňany</li>
                        <li>Vlčí bôb</li>
                        <li>Makkýše</li>
                    </ol>
                </div>
            </Panel>
        </Collapse>
    );
};

export default MenuRovFooter;
